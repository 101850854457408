import React from "react";

import PageMeta from "../components/PageMeta";
import PageHeader from "../components/Pricing/PageHeader";
import PriceOne from "../components/Pricing/PriceOne";
import TabFour from "../components/Tabs/TabFour";
import Layout from "../layout";
import FooterOne from "../layout/Footer/FooterOne";
import Navbar from "../layout/Header/Navbar";

const Price = () => {
  return (
    <Layout>
      <PageMeta title="Pricing — Boushtech" />
      <Navbar />
      <div id="main_content">
        <PageHeader />
        <PriceOne />
        {/* <TabFour /> */}
        <FooterOne />
      </div>
    </Layout>
  );
};

export default Price;
