import React, { Component } from "react";
import { Link } from "react-router-dom";
class ContactInfo extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="contact-info-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="contact-info-wrap bg-wrap">
                  <div className="row no-gutters">
                    <div className="col-md-4">
                      <div className="tt-contact-info">
                        <div className="icon-container">
                          <img
                            src="assets/img/phone-call.jpeg"
                            alt="+1 (760) 284 8466"
                          />
                        </div>

                        <div className="box-content">
                          <h4 className="box-title">
                            <Link
                              to="#"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              +1 (760) 284 8466
                            </Link>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="tt-contact-info">
                        <div className="icon-container">
                          <img
                            src="assets/img/inbox.jpeg"
                            alt="contact@boushtech.com"
                          />
                        </div>

                        <div className="box-content">
                          <h4 className="box-title">
                            <a
                              href="mailto:contact@boushtech.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              contact@boushtech.com
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="tt-contact-info">
                        <div className="icon-container">
                          <img src="assets/img/map.jpeg" alt="USA" />
                        </div>

                        <div className="box-content">
                          <h4 className="box-title">USA</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ContactInfo;
