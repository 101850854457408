import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";

const PriceOne = ({ hasSectionTitle }) => {
  return (
    <>
      <section className="pricing">
        <div className="container">
          {hasSectionTitle ? (
            <SectionTitle
              firstTitle="Choose the plan"
              lastTitle="that suits your team"
              description="After completing your payment, please send us an email at sales@boushtech.com"
              classOption="text-center"
            />
          ) : (
            ""
          )}

          <div className="priceing-tab">
            <nav className="mb-5">
              <div
                className="nav nav-pills justify-content-center pricing-tab-list"
                id="nav-tab"
                role="tablist"
              >
                <a
                  className="active"
                  id="nav-home-tab"
                  data-toggle="tab"
                  href="#nav-home"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  Monthly
                </a>
                <a
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#nav-profile"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  Annualy
                </a>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div className="row advanced-pricing-table">
                  <div
                    className="col-xl-3 col-lg-6 col-md-6"
                    data-wow-dealy="0.4s"
                  >
                    <div className="pricing-table" id="pric-886-0">
                      <div className="price-content-wrapper">
                        <div className="pricing-header pricing-amount">
                          <h2 className="price-title">Basic Plan</h2>
                          <div className="monthly-price">
                            <h3 className="price">
                              $100<span className="period">/month</span>
                            </h3>
                          </div>
                          {/* <h3 className="price-subtitle">
                            14-day trial subscription
                          </h3> */}
                        </div>
                        <ul className="price-feture">
                          <li>15 hours / month</li>
                          <li>Collaboration Tools</li>
                          <li>Backlog Grooming</li>
                          <li>Documentation</li>
                          <li>Refactoring</li>
                          <li>Performance Testing</li>
                          <li>Code Reviews</li>
                          <li>Testing</li>
                          {/* <li>Users Unlimited</li>
                          <li>Downgrade</li> */}
                        </ul>
                        <div className="action">
                          <div className="btn-month monthly-price">
                            <a
                              href="https://buy.stripe.com/3cs8zv0sk0Fv7Di144"
                              target="_blank"
                              rel="noreferrer"
                              className="tt__btn btn-outline"
                            >
                              Buy Now{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-3 col-lg-6 col-md-6 wow pixFadeUp animated"
                    data-wow-dealy="0.4s"
                  >
                    <div className="pricing-table">
                      <div className="price-content-wrapper">
                        <div className="pricing-header pricing-amount">
                          <h2 className="price-title">Medium Plan</h2>
                          <div className="monthly-price">
                            <h3 className="price">
                              $350<span className="period">/month</span>
                            </h3>
                          </div>
                          {/* <h3 className="price-subtitle">7-day free trial</h3> */}
                        </div>
                        <ul className="price-feture">
                          <li>60 hours / month</li>
                          <li>Collaboration Tools</li>
                          <li>Backlog Grooming</li>
                          <li>Documentation</li>
                          <li>Refactoring</li>
                          <li>Performance Testing</li>
                          <li>Code Reviews</li>
                          <li>Testing</li>
                        </ul>
                        <div className="action">
                          <div className="btn-month monthly-price">
                            <a
                              href="https://buy.stripe.com/28o3fb2As87X1eU001"
                              target="_blank"
                              rel="noreferrer"
                              className="tt__btn btn-outline"
                            >
                              Buy Now{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-3 col-lg-6 col-md-6 wow pixFadeUp animated"
                    data-wow-dealy="0.4s"
                  >
                    <div className="pricing-table">
                      <div className="price-content-wrapper">
                        <div className="pricing-header pricing-amount">
                          <h2 className="price-title">Premium Plan</h2>
                          <div className="monthly-price">
                            <h3 className="price">
                              $600<span className="period">/month</span>
                            </h3>
                          </div>
                          {/* <h3 className="price-subtitle">
                            Simple email management
                          </h3> */}
                        </div>
                        <ul className="price-feture">
                          <li>120 hours / month</li>
                          <li>Collaboration Tools</li>
                          <li>Backlog Grooming</li>
                          <li>Documentation</li>
                          <li>Refactoring</li>
                          <li>Performance Testing</li>
                          <li>Code Reviews</li>
                          <li>Testing</li>
                        </ul>

                        <div className="action">
                          <div className="btn-month monthly-price">
                            <a
                              href="https://buy.stripe.com/eVag1X2Asewl7Di7su"
                              target={"_blank"}
                              rel="noreferrer"
                              className="tt__btn btn-outline"
                            >
                              Buy Now{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="pricing-table">
                      <div className="price-content-wrapper">
                        <div className="pricing-header pricing-amount">
                          <h2 className="price-title">Need more?</h2>

                          {/* <div className="monthly-price">
                            <h3 className="price">
                              $75<span className="period">/month</span>
                            </h3>
                          </div> */}

                          <h3 className="price-subtitle">If your team needs</h3>
                        </div>
                        <ul className="price-feture">
                          <li>Everything on the Premium Plan</li>
                          <li>Strategic Initiative Planning</li>
                          <li> Custom Solutions </li>
                          <li> and more</li>
                        </ul>

                        <div className="action">
                          <div className="btn-month monthly-price">
                            <Link to="/contact" className="tt__btn btn-outline">
                              Contact Us{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div className="row advanced-pricing-table">
                  <div
                    className="col-xl-3 col-lg-6 col-md-6"
                    data-wow-dealy="0.4s"
                  >
                    <div className="pricing-table">
                      <div className="price-content-wrapper">
                        <div className="pricing-header pricing-amount">
                          <h2 className="price-title">Basic Plan</h2>
                          <div className="yearly-price">
                            <h3 className="price">
                              $960 <span className="period">/year</span>
                            </h3>
                          </div>
                          {/* <h3 className="price-subtitle">
                            14-day trial subscription
                          </h3> */}
                        </div>
                        <ul className="price-feture">
                          <li>15 hours / month</li>
                          <li>Collaboration Tools</li>
                          <li>Backlog Grooming</li>
                          <li>Documentation</li>
                          <li>Refactoring</li>
                          <li>Performance Testing</li>
                          <li>Code Reviews</li>
                          <li>Testing</li>
                        </ul>
                        <div className="action">
                          <div className="btn-annual yearly-price">
                            <a
                              href="https://buy.stripe.com/4gwg1Xdf6ewlg9OeUX"
                              target={"_blank"}
                              rel="noreferrer"
                              className="tt__btn btn-outline"
                            >
                              Buy Now{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-3 col-lg-6 col-md-6 wow pixFadeUp animated"
                    data-wow-dealy="0.4s"
                  >
                    <div className="pricing-table">
                      <div className="price-content-wrapper">
                        <div className="pricing-header pricing-amount">
                          <h2 className="price-title">Medium Plan</h2>

                          <div className="yearly-price">
                            <h3 className="price">
                              $3360 <span className="period">/year</span>
                            </h3>
                          </div>
                          {/* <h3 className="price-subtitle">7-day free trial</h3> */}
                        </div>
                        <ul className="price-feture">
                          <li>60 hours / month</li>
                          <li>Collaboration Tools</li>
                          <li>Backlog Grooming</li>
                          <li>Documentation</li>
                          <li>Refactoring</li>
                          <li>Performance Testing</li>
                          <li>Code Reviews</li>
                          <li>Testing</li>
                        </ul>
                        <div className="action">
                          <div className="btn-annual yearly-price">
                            <a
                              href="https://buy.stripe.com/3cs8zv5ME3RH0aQfZ2"
                              target={"_blank"}
                              rel="noreferrer"
                              className="tt__btn btn-outline"
                            >
                              Buy Now{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-3 col-lg-6 col-md-6 "
                    data-wow-dealy="0.4s"
                  >
                    <div className="pricing-table featured">
                      <div className="price-content-wrapper">
                        <div className="pricing-header pricing-amount">
                          <h2 className="price-title">Premium Plan</h2>
                          <div className="yearly-price">
                            <h3 className="price">
                              $5760<span className="period">/year</span>
                            </h3>
                          </div>
                          {/* <h3 className="price-subtitle">
                            Simple email management
                          </h3> */}
                        </div>
                        <ul className="price-feture">
                          <li>120 hours / month</li>
                          <li>Collaboration Tools</li>
                          <li>Backlog Grooming</li>
                          <li>Documentation</li>
                          <li>Refactoring</li>
                          <li>Performance Testing</li>
                          <li>Code Reviews</li>
                          <li>Testing</li>
                        </ul>

                        <div className="action">
                          <div className="btn-annual yearly-price">
                            <a
                              href="https://buy.stripe.com/7sI8zva2U1Jz3n2005"
                              target={"_blank"}
                              rel="noreferrer"
                              className="tt__btn btn-outline"
                            >
                              Buy Now{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="pricing-table">
                      <div className="price-content-wrapper">
                        <div className="pricing-header pricing-amount">
                          <h2 className="price-title">Need more?</h2>
                          {/* <div className="yearly-price">
                            <h3 className="price">
                              $130 <span className="period">/month</span>
                            </h3>
                          </div> */}
                          <h3 className="price-subtitle">If your team needs</h3>
                        </div>
                        <ul className="price-feture">
                          <li>Everything on the Premium Plan</li>
                          <li>Strategic Initiative Planning</li>
                          <li> Custom Solutions </li>
                          <li> and more</li>
                        </ul>

                        <div className="action">
                          <div className="btn-annual yearly-price">
                            <Link to="/contact" className="tt__btn btn-outline">
                              Contact Us
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PriceOne;
