import React, { Component } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
class ServiceThree extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="service-three area">
          <div className="container">
            <SectionTitle
              firstTitle="Our mission"
              lastTitle=""
              description="We believe that the future is digital. We also believe that without embracing new technology, businesses postpone their growth."
              descriptionTwo=""
              descriptionThree="We help businesses unveil and satisfy demand for digital transformation by providing engineering and consulting services that foster competitiveness and innovation."
            />
            <div className="row">
              <div className="col-md-4">
                <div
                  className="tt-icon-box style-three wow fadeInUp"
                  data-wow-delay="0.7s"
                >
                  <div className="icon-container">
                    <img
                      src="media/feature/trending-up.svg"
                      alt="Direct Access"
                    />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <Link to="/service">Direct Access</Link>
                    </h4>

                    <p className="description">
                      We have a pool of experts with various technological and
                      domain backgrounds and qualification levels, which also
                      includes resources available on demand, who provide
                      full-cycle implementation services, taking up projects at
                      any stage.
                    </p>
                    {/* <Link to="/service" className="tt-more-link">
                      Read More<i className="feather-arrow-right"></i>
                    </Link> */}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div
                  className="tt-icon-box style-three wow fadeInUp"
                  data-wow-delay="0.9s"
                >
                  <div className="icon-container color__two">
                    <img src="media/feature/bell.svg" alt="Expertise" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <Link to="service">Expertise</Link>
                    </h4>

                    <p className="description">
                      For several years on the market, Boushtech has completed
                      50+ projects, gaining comprehensive technical and
                      industry-specific knowledge, as well as enrolled in
                      partnership programs with such industry leaders as
                      Microsoft, Amazon, Salesforce, Atlassian, and others.
                    </p>

                    {/* <Link to="/service" className="tt-more-link">
                      Read More<i className="feather-arrow-right"></i>
                    </Link> */}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div
                  className="tt-icon-box style-three wow fadeInUp"
                  data-wow-delay="1.1s"
                >
                  <div className="icon-container color__three">
                    <img src="media/feature/tablet.svg" alt="Transparency" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <Link to="service">Transparency</Link>
                    </h4>

                    <p className="description">
                      We ensure collaboration transparency through our certified
                      project managers assigned to every project, regular
                      reporting, demos, and the client’s access to the test
                      stand and project tracking tools.
                    </p>

                    {/* <Link to="/service" className="tt-more-link">
                      Read More<i className="feather-arrow-right"></i>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ServiceThree;
