import React, { Component } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";

class FeatureOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="service">
          <div className="container">
            <SectionTitle
              firstTitle="Technology consulting: our approach"
              lastTitle=""
              description="Our team supports businesses at different stages of their software development and implementation projects. We have the skills and expertise to shape early concepts into detailed solution specifications. We also manage full-cycle development of enterprise systems of any complexity and integrate them smoothly into business processes and IT infrastructures."
              classOption="text-center"
            />
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div
                  className="tt-icon-box style-one wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <div className="icon-container">
                    <img src="media/feature/profile.svg" alt="" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <a href="service.html">
                        Software implementation strategy
                      </a>
                    </h4>

                    <p className="description">
                      We help companies draw up their short- and long-term
                      software implementation roadmaps. We advocate reasonable
                      software investment while ensuring smooth business
                      transformation through custom solutions and their safe
                      adoption.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-sm-6 offset-md-1">
                <div
                  className="tt-icon-box style-one wow fadeInUp"
                  data-wow-delay="0.7s"
                >
                  <div className="icon-container">
                    <img src="media/feature/copy.svg" alt="" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <a href="service.html">Technology advisory services</a>
                    </h4>

                    <p className="description">
                      Boushtech's software consultants assist our customers in
                      selecting the right technology stacks for their solutions.
                      Be it a programming language or a ready-to-use platform,
                      we help businesses review technologies pros and cons
                      before starting their projects.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-sm-6 offset-md-1">
                <div
                  className="tt-icon-box style-one wow fadeInUp"
                  data-wow-delay="0.9s"
                >
                  <div className="icon-container">
                    <img src="media/feature/bulb.svg" alt="" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <a href="service.html">Business solution consulting</a>
                    </h4>

                    <p className="description">
                      When companies have doubts choosing the right software for
                      their challenges, we provide business solution consulting.
                      Together, we decide on the system to implement (CRM, ERP,
                      DMS, CMS, etc.) as well as the development and
                      customization scope.
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-3 col-sm-6">
                <div
                  className="tt-icon-box style-one wow fadeInUp"
                  data-wow-delay="1.1s"
                >
                  <div className="icon-container">
                    <img src="media/feature/analytics.svg" alt="" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <a href="service.html">Sales Enablement</a>
                    </h4>

                    <p className="description">
                      I only a quid me old mucker Bambo
                      <br /> led bender chinwag pardon.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-sm-6 offset-md-1">
                <div
                  className="tt-icon-box style-one wow fadeInUp"
                  data-wow-delay="1.3s"
                >
                  <div className="icon-container">
                    <img src="media/feature/hand_shake.svg" alt="" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <a href="service.html">Company Training</a>
                    </h4>

                    <p className="description">
                      I only a quid me old mucker Bambo
                      <br /> led bender chinwag pardon.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-sm-6 offset-md-1">
                <div
                  className="tt-icon-box style-one wow fadeInUp"
                  data-wow-delay="1.5s"
                >
                  <div className="icon-container">
                    <img src="media/feature/folder.svg" alt="" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <a href="service.html">Automation Builder</a>
                    </h4>

                    <p className="description">
                      I only a quid me old mucker Bambo
                      <br /> led bender chinwag pardon.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FeatureOne;
